import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Fade from "@material-ui/core/Fade";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React from "react";
import "./App.css";
import { postTotemVerify } from "./utils";

let urlParams = new URLSearchParams(window.location.search);
// let id = urlParams.get("id");
//let token = urlParams.get("token");
let building = urlParams.get("building");

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="#">
        WeAccess
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const Logo = () => (
  <svg width={120} height={133} viewBox="0 0 88.651 75.461">
    <g strokeWidth={0.265}>
      <path
        d="M.132 74.528V28.242L44.216.241l.017 46.113z"
        fill="#1d7ccf"
        stroke="#1d7ccf"
      />
      <path
        d="M44.491 46.354L88.52 74.641V27.862L44.474.241zM36.178 63.508l-.073 10.378s-.055 1.461 1.187 1.443c1.243-.018 13.666-.037 13.666-.037s1.754.147 1.718-1.224c-.037-1.37-.055-10.34-.055-10.34s.037-1.316-1.041-1.353c-1.078-.036-14.142-.018-14.142-.018s-1.242 0-1.26 1.151z"
        fill="#2394f7"
        stroke="#2394f7"
      />
      <path
        d="M41.11 62.266l.051-3.576c.286-2.797 5.07-4.039 6.357 0l.064 3.656 2.183.026v-4.755c-.525-1.601-2.981-5.426-8.204-3.386-.906.432-2.493 1.857-2.854 3.348l-.026 4.663z"
        fill="#2394f7"
        stroke="#2394f7"
      />
    </g>
  </svg>
);

const verifyME = (dni, setWait, setError) => {
  setWait(true);
  console.log(dni, building);
  if (dni && building) {
    postTotemVerify(building, dni)
      .then(data => {
        alert("Validado con exito");
        console.log(data);
        return true;
      })
      .catch(e => {
        console.error(e);
        setWait(false);
        setError(true);

        return false;
      });
  } else {
    setError(true);
    setWait(false);
  }
};

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

function App() {
  const classes = useStyles();
  // const { latitude, longitude } = usePosition(true);
  const [wait, setWait] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [dni, setDni] = React.useState(null);

  // console.log(latitude, longitude);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Logo />

        <Typography component="h1" variant="h5">
          Bienvenido a {building !== "" ? building : "No Habilitado"}
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="dni"
            label="DNI"
            name="dni"
            autoComplete="dni"
            value={dni}
            onChange={e => setDni(e.currentTarget.value)}
            autoFocus
          />

          <Fade in={building != null}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              disabled
              fullWidth
              id="building"
              label="EDIFICIO"
              name="building"
              value={building}
            />
          </Fade>

          <Fade in={building != null}>
            <Button
              type="submit"
              fullWidth
              size="large"
              variant="contained"
              onClick={() =>
                error
                  ? alert("Verifique la invitación")
                  : verifyME(dni, setWait, setError)
              }
              color={error ? "secondary" : "primary"}
              className={classes.submit}
              disabled={wait}
            >
              {wait
                ? "En proceso de verificación"
                : error
                ? "Ocurrio un error"
                : "Validar Invitacion"}
            </Button>
          </Fade>

          <Fade in={building == null}>
            <Typography
              component="h1"
              variant="h5"
              style={{ textAlign: "center" }}
            >
              Debe poseer un Edificio valido
            </Typography>
          </Fade>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
    // <Box className={classes.root}>
    //   <Container fixed maxWidth="xl">
    //     <Grid container >
    //       <Grid item xs={12}>
    //         <Fade in={latitude != null & longitude != null}>
    //           <div>
    //             {latitude != null & longitude != null ? <MapCustom position={[latitude, longitude]} zoom={18} /> : (null)}
    //           </div>
    //         </Fade>

    //         <Fade in={latitude == null & longitude == null}>
    //           <Grid container direction="column"
    //       alignItems="center"
    //       justify="center">
    //         <CircularProgress />
    //       </Grid>
    //         </Fade>
    //       </Grid>

    //       <br />

    //       <Grid item xs={12}>
    //         <Typography variant="h4" gutterBottom>
    //           Usted se encuentra aqui: {latitude != null & longitude != null ? `${latitude},${longitude}` : "Cargando"}
    //         </Typography>
    //       </Grid>

    //       <br />
    //       <br />

    //       {token === null && id === null ? (null) : latitude != null & longitude != null ?
    //       <Grid item xs={12}>
    //         <Button disabled={wait} fullWidth size="large" onClick={() => error ? alert("Verifique la invitación") : verifyME(setWait, setError)} variant="contained" color={error ? "secondary" : "primary"}>
    //           {wait ? "En proceso de verificación" : error ? "Ocurrio un error" : "Verificarse"}
    //         </Button>
    //       </Grid> : (null)}
    //     </Grid>
    //   </Container>
    // </Box>
  );
}

export default App;
