const axios = require("axios");
//const URL_API = process.env.REACT_APP_URL_API

export const postTotemVerify = async (building, dni) => {
  try {
    console.log(process.env);
    let data = await axios.post(
      `https://${building}.wehaus.com/api/v2/autorizados/totem/authorized`,
      {
        autorizado: {
          dni: dni
        }
      }
    );
    // let json = await data.json()

    console.log(data);

    return data;
  } catch (e) {
    throw e;
  }
};
